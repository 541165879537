import { Message } from "../../utils/agentTypes";
import React from "react";
import { AgentMessageBox } from "./AgentMessageBox";
import { AgentDoneMessageBox } from "./DoneMessageBox";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { BounceLoader } from "react-spinners";
import { TutorialMessageBox } from "./TutorialMessageBox";
import { PromptEditorInterfaceState } from "../../utils/types";

interface MessageBoxProps {
  message?: string;
  side: "left" | "right";
  selected?: boolean;
  loading?: boolean;

  clickHandler?: () => void;
}

export const MessageBox = ({ message, side, clickHandler, loading = false }: MessageBoxProps) => {
  const color = side === "right" ? "#E0EADB" : "#EEF1FB";
  const space = (
    <Box width="20%" height="100%">
      {" "}
    </Box>
  );
  const message_box = (
    <Box style={{ backgroundColor: color, borderRadius: 10, padding: 18 }} onClick={clickHandler}>
      <Typography> {message} </Typography>
    </Box>
  );
  const loading_box = (
    <Box
      style={{
        backgroundColor: color,
        borderRadius: 10,
        padding: 18,
        justifyContent: "center",
        display: "flex",
      }}
    >
      <BounceLoader size={25} />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        mt: 4,
      }}
    >
      {side === "left" ? <></> : space}
      <Box width="80%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="#a7a7a7"> {side === "left" ? "Browser robot" : "User"} </Typography>
          <Box />
        </Box>
        {loading ? loading_box : message_box}
      </Box>
      {side === "right" ? <></> : space}
    </Box>
  );
};

interface MessageBoxesProps {
  messages: Message[];
  selectedIndex: number;
  waitingAgentResponse: boolean;
  handleClickMessage: (index: number) => void;
  handlePromptingCompleted: () => void;
  interfaceState: PromptEditorInterfaceState;
}

export function MessageBoxes({
  messages,
  selectedIndex,
  waitingAgentResponse,
  handleClickMessage,
  handlePromptingCompleted,
  interfaceState,
}: MessageBoxesProps): React.JSX.Element {
  const result = messages.map((message: Message, index: number) => {
    const isLastMessage = messages.length - 1 === index;
    if (message.type === "agent") {
      return (
        <AgentMessageBox
          message={message}
          clickHandler={() => handleClickMessage(index)}
          selected={selectedIndex == index && !waitingAgentResponse}
          status_type={
            isLastMessage && interfaceState === "WaitingForConfirmation"
              ? "waiting_for_approval"
              : "confirmed"
          }
        />
      );
    } else if (message.type === "user") {
      return <MessageBox message={message.message} side="right" />;
    } else if (message.type === "done") {
      return <AgentDoneMessageBox finishRunHandler={handlePromptingCompleted} />;
    } else if (message.type === "tutorial") {
      return <TutorialMessageBox />;
    }
  });

  return <>{...result}</>;
}
