import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button, StepButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export interface AgentSetupStepperProps {
  readonly activeStep: number;
  readonly agentUuid: string;
  readonly handleNextButtonSave: () => Promise<void>;
}

export default function AgentSetupStepper({
  activeStep,
  agentUuid,
  handleNextButtonSave,
}: AgentSetupStepperProps) {
  const navigate = useNavigate();

  const steps = ["Credentials & settings", "Task description", "Review and run", "Schedule"];

  const links = [
    `/app/agents/${agentUuid}/settings`,
    `/app/agents/${agentUuid}/chat`,
    `/app/agents/${agentUuid}/review`,
    `/app/agents/${agentUuid}/schedule`,
  ];

  const goToStep = (step: number) => {
    handleNextButtonSave();
    navigate(links[step]);
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: "0.5px solid #dcdcdc",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          py: 2,
        }}
      >
        <Box sx={{ width: "25%" }} />
        <Box sx={{ width: "50%" }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, i) => (
              <Step key={label}>
                <StepButton onClick={() => goToStep(i)}>
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ width: "25%", display: "flex", justifyContent: "right", px: 2 }}>
          <Button
            endIcon={<NavigateNextIcon />}
            variant="contained"
            onClick={() => goToStep(activeStep + 1)}
          >
            {activeStep + 1 === steps.length ? "Finish" : "Next"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
