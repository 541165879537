import { Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import { BounceLoader } from "react-spinners";
import React from "react";
import { AgentMessage } from "../../utils/agentTypes";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface AgentMessageBoxProps {
  message?: AgentMessage;
  selected?: boolean;
  loading?: boolean;
  done?: boolean;
  clickHandler?: () => void;
  status_type: "confirmed" | "waiting_for_approval" | "none";
}

type AgentActionT = {
  name: string;
  args: Record<string, any>;
};

const AgentActionDone = () => {
  return (
    <Typography>
      Agent thinks it completed your last instructions. What next? Type the next prompt or click on
      Next in the top right corner to proceed to scheduling.
    </Typography>
  );
};
const AgentAction = ({ name, args }: AgentActionT) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography> {name && name} </Typography>
      {args &&
        Object.entries(args).map(([key, value]) => (
          <Box key={key} sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ mr: 2 }}>{key}</Typography>
            <Typography>{JSON.stringify(value)}</Typography>
          </Box>
        ))}
    </Box>
  );
};

export const AgentMessageBox = ({
  message,
  clickHandler,
  selected = false,
  loading = false,
  status_type = "none",
}: AgentMessageBoxProps) => {
  const color = "#EEF1FB";
  const space = <Box width="20%" height="100%"></Box>;
  const selectedProps = selected ? { border: "1px solid", borderColor: "#142E90" } : {};
  const message_box = (
    <Box
      style={{ backgroundColor: color, borderRadius: 10, padding: 18, ...selectedProps }}
      onClick={clickHandler}
    >
      <Typography> {message.message.thought} </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography color="#666">Action</Typography>
      {!message.message.action ? <AgentActionDone /> : <AgentAction {...message.message.action} />}
    </Box>
  );

  const loading_box = (
    <Box
      style={{
        backgroundColor: color,
        borderRadius: 10,
        padding: 18,
        justifyContent: "center",
        display: "flex",
      }}
    >
      <BounceLoader size={25} />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        mb: 1,
        mt: 4,
      }}
    >
      <Box width="80%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="#a7a7a7">Browser robot</Typography>
          <Box />
          {status_type === "confirmed" && (
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <CheckCircleIcon sx={{ color: "#142E90", mr: 0.5, fontSize: "16px" }} />
              <Typography color="#142E90"> confirmed </Typography>
            </Box>
          )}
          {status_type === "waiting_for_approval" && (
            <Typography color="rgba(0, 0, 0, 0.3)"> Waiting your approval </Typography>
          )}
        </Box>
        {loading ? loading_box : message_box}
      </Box>
      {space}
    </Box>
  );
};
