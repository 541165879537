import { Box, Typography } from "@mui/material";
import { AgentStepDto } from "../../../../../spec/bo";
import RestoreIcon from "@mui/icons-material/Restore";

interface ThoughtsListProps {
  steps: AgentStepDto[] | null;
  selectedIndex: number | null;
  onThoughtClick: (index: number) => void;
}

const ThoughtsList = ({ steps, selectedIndex, onThoughtClick }: ThoughtsListProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
          Browser agent
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <RestoreIcon sx={{ fontSize: 14, color: "rgba(0, 0, 0, 0.6)" }} />
          <Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Estimated run 4 minutes
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {steps.length === 0 ? (
          <Box sx={{ p: 3, backgroundColor: "rgba(0, 0, 0, 0.04)", borderRadius: "6px" }}>
            <Typography color="text.secondary">
              Agent thoughts will appear here once run is completed.
            </Typography>
          </Box>
        ) : (
          steps.map((step, index) => (
            <Box
              key={`${step.thought}-${step.screenshot}`}
              onClick={() => onThoughtClick(index)}
              sx={{
                p: 3,
                backgroundColor: selectedIndex === index ? "#EEF1FB" : "rgba(0, 0, 0, 0.04)",
                borderRadius: "6px",
                cursor: "pointer",
                transition: "all 0.2s ease",
                transformOrigin: "center left",
                position: "relative",
                ...(selectedIndex === index && {
                  border: "1px solid #BCC8F5",
                  transform: "scale(1.01)",
                }),
              }}
            >
              <Typography>{step.thought}</Typography>
            </Box>
          ))
        )}
      </Box>
    </>
  );
};

export default ThoughtsList;
