import { Box, Button, Typography } from "@mui/material";
import { AgentStepDto } from "../../../../../spec/bo";

interface ScreenshotViewerProps {
  steps: AgentStepDto[];
  selectedIndex: number | null;
  setSelectedIndex: (index: number | ((prev: number) => number)) => void;
}

const ScreenshotViewer = ({ steps, selectedIndex, setSelectedIndex }: ScreenshotViewerProps) => {
  if (!steps || selectedIndex === null) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography color="text.secondary">Select a thought to see screenshots</Typography>
      </Box>
    );
  }

  const selectedStep = steps[selectedIndex];
  const previousStep = selectedIndex > 0 ? steps[selectedIndex - 1] : null;
  const nextStep = selectedIndex < steps.length - 1 ? steps[selectedIndex + 1] : null;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#eee",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box sx={{ flex: "1 1 65%", p: 2, minHeight: 0 }}>
        {selectedStep.screenshot && (
          <Box sx={{ width: "100%", height: "100%", maxWidth: "1200px", margin: "0 auto" }}>
            <img
              src={`data:image/png;base64,${selectedStep.screenshot}`}
              alt={`Step ${selectedIndex + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "6px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ flex: "1 1 35%", p: 2, display: "flex", flexDirection: "column", minHeight: 0 }}>
        <Box sx={{ flex: 1, display: "flex", gap: 2, justifyContent: "center", minHeight: 0 }}>
          {previousStep?.screenshot && (
            <Box sx={{ width: "45%", height: "100%", maxWidth: "500px" }}>
              <img
                src={`data:image/png;base64,${previousStep.screenshot}`}
                alt="Previous step"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "6px",
                  opacity: 0.7,
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          )}
          {nextStep?.screenshot && (
            <Box sx={{ width: "45%", height: "100%", maxWidth: "500px" }}>
              <img
                src={`data:image/png;base64,${nextStep.screenshot}`}
                alt="Next step"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "6px",
                  opacity: 0.7,
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, px: 2 }}>
          <Button
            variant="contained"
            disabled={!previousStep}
            onClick={() => setSelectedIndex(prev => prev - 1)}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            disabled={!nextStep}
            onClick={() => setSelectedIndex(prev => prev + 1)}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ScreenshotViewer;
