import React, { SyntheticEvent, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Tab, Tabs, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import update from "immutability-helper";
import SidebarWindow from "../SidebarWindow";
import AgentSetupStepperWindow from "./AgentSetupStepperWindow";
import TwoFactorSetup from "../TwoFactorAuth/TwoFactorSetup";
import { AgentConfigDTO } from "../../../../spec/agent";
import { useBoApiClient } from "../../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../../spec/bo";

const AgentSettingsView = (): React.JSX.Element => {
  const { agentUuid } = useParams();
  const boApiClient = useBoApiClient();
  const navigate = useNavigate();

  const [agent, setAgent] = React.useState<WebAgentDto | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const setUrl = (url: string) => {
    setAgent(update(agent, { config: { url: { $set: url } } }));
  };

  const getUsername = () => {
    return agent?.config?.parameters?.username?.value;
  };

  const getPassword = () => {
    return agent?.config?.parameters?.password?.value;
  };

  const handleTwoFactorSetup = (qrData: string) => {
    agent?.config &&
      setAgent(
        update(agent, {
          config: {
            parameters: {
              $set: {
                ...(agent.config.parameters || {}),
                otp: {
                  type: "otp",
                  uri: qrData,
                },
              },
            },
          },
        })
      );
  };

  const handleStringParamChange = (fieldName: string, newValue: string) => {
    setAgent(
      update(agent, {
        config: { parameters: { [fieldName]: { $set: { type: "string", value: newValue } } } },
      })
    );
  };

  const handleSubmit = async () => {
    const request: AgentConfigDTO = {
      url: agent?.config?.url,
      prompt: agent?.config?.prompt,
      options: agent?.config?.options,
      // @ts-expect-error
      parameters: agent?.config?.parameters,
    };

    boApiClient.proxyWebAgentAgents.updateAgent(agentUuid, request).then(() => {
      navigate(`/app/agents/${agentUuid}/chat`);
    });
  };

  useEffect(() => {
    boApiClient.proxyWebAgentAgents.getAgent(agentUuid).then(agent => {
      setAgent(agent);
    });
  }, [agentUuid, boApiClient.proxyWebAgentAgents]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const renderLoginDetails = () => (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        InputLabelProps={{ shrink: true }}
        label="Username"
        value={getUsername()}
        onChange={e => handleStringParamChange("username", e.target.value)}
        sx={{ maxWidth: "400px", mt: 4 }}
      />
      <TextField
        InputLabelProps={{ shrink: true }}
        label="Password"
        value={getPassword()}
        type="password"
        onChange={e => handleStringParamChange("password", e.target.value)}
        sx={{ maxWidth: "400px", mt: 1 }}
      />
    </Box>
  );

  return (
    <SidebarWindow>
      <Box sx={{ width: "100%", justifyContent: "center", display: "flex", overflowY: "auto" }}>
        <AgentSetupStepperWindow
          stepperOptions={{
            activeStep: 0,
            agentUuid: agentUuid,
            handleNextButtonSave: handleSubmit,
          }}
        >
          <Box width="100%" sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ width: "60%", minWidth: 300, mx: "auto", mt: 4, p: 2, boxShadow: 3 }}
            >
              <Typography variant="h6" gutterBottom>
                Browser agent
              </Typography>

              <Typography>Starting URL</Typography>
              <TextField
                fullWidth
                margin="normal"
                required={true}
                value={agent?.config?.url}
                placeholder="https://google.com"
                onChange={e => setUrl(e.target.value)}
                autoFocus={true}
              />

              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Parameters
              </Typography>
              <Typography variant="body2" color="#565656" gutterBottom sx={{ mt: 1 }}>
                These are secrets that will not be provided to external data processors.
              </Typography>
              <Typography variant="body2" color="#565656" gutterBottom>
                To use the post-processing use them within the prompting like this:
              </Typography>
              <Typography variant="body2" color="#565656" gutterBottom>
                &gt; Log in using $username and $password
              </Typography>

              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                sx={{
                  mt: 2,
                  minHeight: "unset",
                  "& .MuiTabs-flexContainer": {
                    display: "flex",
                    alignItems: "center !important",
                    maxHeight: "32px",
                  },
                  ".MuiTab-iconWrapper": {
                    marginBottom: "0 !important",
                    marginRight: "4px",
                  },
                  ".MuiTab-root": {
                    borderRadius: "24px !important",
                  },
                  ".Mui-selected": {
                    color: `#142E90 !important`,
                    backgroundColor: "#EEF1FB",
                    minHeight: "unset",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Tab
                  label="Login details"
                  sx={{
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    height: "32px",
                  }}
                />
                <Tab
                  label="Two-Factor Authentication (2FA)"
                  sx={{
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    height: "32px",
                  }}
                />
              </Tabs>

              {tabIndex === 0 && renderLoginDetails()}
              {tabIndex === 1 && <TwoFactorSetup onConfigured={handleTwoFactorSetup} />}
            </Box>
          </Box>
        </AgentSetupStepperWindow>
      </Box>
    </SidebarWindow>
  );
};

export default AgentSettingsView;
