import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CachedIcon from "@mui/icons-material/Cached";

interface PromptSectionProps {
  prompt: string;
  onRun: (updatedPrompt: string) => void;
  isRunning: boolean;
}

export const PromptSection = ({ prompt, onRun, isRunning }: PromptSectionProps) => {
  const [editedPrompt, setEditedPrompt] = useState(prompt);

  useEffect(() => {
    setEditedPrompt(prompt);
  }, [prompt]);

  const handleReset = () => {
    setEditedPrompt(prompt);
  };

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "85%",
          marginLeft: "15%",
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            color: "rgba(0,0,0,0.6)",
            fontSize: "12px",
          }}
        >
          Your prompt
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <CheckCircleIcon sx={{ color: "#64B075", fontSize: "16px" }} />
            <Typography sx={{ color: "#2A512A", fontSize: "12px" }}>Completed</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            backgroundColor: "#E0EADB",
            border: "3px solid #64B075",
            borderRadius: "6px",
            p: 2,
            mb: 4,
            width: "85%",
            ml: "15%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            multiline
            value={editedPrompt}
            onChange={e => setEditedPrompt(e.target.value)}
            disabled={isRunning}
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                backgroundColor: "transparent",
                "& fieldset": {
                  border: "none",
                },
              },
            }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              onClick={handleReset}
              disabled={isRunning}
              startIcon={<CachedIcon />}
              sx={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #BCC8F5",
                color: "#142E90",
                "&:hover": { backgroundColor: "#f5f5f5" },
                "& .MuiSvgIcon-root": {
                  color: "#142E90",
                },
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={() => onRun(editedPrompt)}
              disabled={isRunning}
              startIcon={
                isRunning ? <CircularProgress size={20} color="inherit" /> : <PlayArrowIcon />
              }
            >
              {isRunning ? "Running..." : "Run"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PromptSection;
