import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useBoApiClient } from "../../../../hooks/useBoApiClient";
import SidebarWindow from "../SidebarWindow";
import AgentSetupStepperWindow from "./AgentSetupStepperWindow";
import Loader from "../../../../components/Loader/Loader";
import { Box, Typography } from "@mui/material";
import { AgentRunHistoryDto, WebAgentDto } from "../../../../spec/bo";
import { useParams } from "react-router-dom";
import { SignalRContext } from "../../../../context/SignalRContext";
import AgentRunAlert from "../AgentRunAlert";
import PromptSection from "./components/PromptSection";
import ThoughtsList from "./components/ThoughtsList";
import ScreenshotViewer from "./components/ScreenshotViewer";
import { useAgents } from "../../hooks/useAgents";
import ThoughtsPlaceholder from "./components/ThoughtsPlaceholder";

interface IAgentRunCompleted {
  id: string;
  agent_id: string;
  success: boolean;
}

export const ReviewAndRunView = () => {
  const { agentUuid } = useParams();
  const [agent, setAgent] = useState<WebAgentDto | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null); // NEW: Error state
  const navigate = useNavigate();
  const boApiClient = useBoApiClient();
  const [runningAgent, setRunningAgent] = useState<WebAgentDto | null>(null);
  const [showRunAlert, setShowRunAlert] = useState(false);
  const [runHistory, setRunHistory] = useState<AgentRunHistoryDto | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const [selectedThoughtIndex, setSelectedThoughtIndex] = useState<number | null>(null);
  const [, setLatestRunId] = useState<string | null>(null);

  const { updateAgentPrompt } = useAgents(2);
  const { connection } = useContext(SignalRContext);

  useEffect(() => {
    const fetchLatestRun = async () => {
      try {
        const latestRun = await boApiClient.proxyWebAgentAgents.getLatestRun(agentUuid);
        if (latestRun) {
          setLatestRunId(latestRun.runId);
          await handleRunHistoryFetch(agentUuid, latestRun.runId);
        }
      } catch (error) {
        console.error("Failed to load latest run:", error);
      }
    };

    if (agentUuid) {
      fetchLatestRun();
    }
  }, [agentUuid]);

  const handleRunHistoryFetch = useCallback(
    async (agentId: string, runId: string) => {
      try {
        if (isRunning) setIsRunning(false);
        const history = await boApiClient.proxyWebAgentAgents.getRunHistory(agentId, runId);
        setRunHistory(history);
        setSelectedThoughtIndex(0);
      } catch (error) {
        setError("Failed to load run history");
        console.error(error);
      }
    },
    [boApiClient, isRunning]
  );

  const handleWebAgentRunCompleted = useCallback(
    (agentRunCompleted: IAgentRunCompleted) => {
      handleRunHistoryFetch(agentRunCompleted.agent_id, agentRunCompleted.id);
      setIsRunning(false);
      setShowRunAlert(false);
    },
    [handleRunHistoryFetch]
  );

  useEffect(() => {
    if (connection) {
      connection.on("web_agent_run_completed", handleWebAgentRunCompleted);
    }
    return () => {
      if (connection) {
        connection.off("web_agent_run_completed", handleWebAgentRunCompleted);
      }
    };
  }, [connection, handleWebAgentRunCompleted]);

  useEffect(() => {
    const fetchAgent = async () => {
      try {
        const agent = await boApiClient.proxyWebAgentAgents.getAgent(agentUuid);
        setAgent(agent);
      } catch (error) {
        setError("Failed to load agent details");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAgent();
  }, [agentUuid]);

  const runAgent = async (updatedPrompt: string) => {
    try {
      setIsRunning(true);
      setError(null);
      setRunHistory(null);
      await updateAgentPrompt(agentUuid, updatedPrompt);
      setRunningAgent(agent);
      setShowRunAlert(true);
      await boApiClient.proxyWebAgentAgents.startRun(agentUuid);
    } catch (error) {
      setError("Failed to start agent run");
      console.error("Failed to run agent:", error);
    }
  };

  const renderScreenshotPlaceholder = () => (
    <Typography color="text.secondary" sx={{ textAlign: "center", margin: "auto" }}>
      Screenshots will appear here
    </Typography>
  );

  return (
    <SidebarWindow>
      <AgentSetupStepperWindow
        stepperOptions={{
          activeStep: 2,
          agentUuid,
          handleNextButtonSave: async () => navigate(`/app/agents/${agentUuid}/schedule`),
        }}
      >
        {error && (
          <Typography color="error" sx={{ p: 2 }}>
            {error}
          </Typography>
        )}

        {loading ? (
          <Loader isLoading={loading} />
        ) : (
          <Box sx={{ display: "flex", height: "100%", width: "100%", overflow: "hidden" }}>
            <Box
              sx={{
                width: "35%",
                maxWidth: "500px",
                borderRight: "1px solid #dcdcdc",
                overflowY: "auto",
              }}
            >
              <Box sx={{ p: 3 }}>
                <PromptSection
                  prompt={agent?.config.prompt}
                  onRun={runAgent}
                  isRunning={isRunning}
                />
                {runHistory ? (
                  <ThoughtsList
                    steps={runHistory.result?.history?.stages?.[0]?.steps || []}
                    selectedIndex={selectedThoughtIndex}
                    onThoughtClick={setSelectedThoughtIndex}
                  />
                ) : (
                  <ThoughtsPlaceholder />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#eee",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {runHistory ? (
                  <ScreenshotViewer
                    steps={runHistory.result.history.stages[0]?.steps || []}
                    selectedIndex={selectedThoughtIndex}
                    setSelectedIndex={setSelectedThoughtIndex}
                  />
                ) : (
                  renderScreenshotPlaceholder()
                )}
              </Box>
            </Box>
          </Box>
        )}

        {showRunAlert && runningAgent && (
          <AgentRunAlert
            agentName={runningAgent.name}
            onClose={() => {
              setShowRunAlert(false);
              setRunningAgent(null);
            }}
          />
        )}
      </AgentSetupStepperWindow>
    </SidebarWindow>
  );
};
