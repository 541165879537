import { useEffect, useState } from "react";
import { AgentConfigDTO } from "../../../spec/agent";
import { useBoApiClient } from "../../../hooks/useBoApiClient";
import { WebAgentDto } from "../../../spec/bo";

export const useAgents = (selectedTab: number) => {
  const [agents, setAgents] = useState<WebAgentDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const boApiClient = useBoApiClient();

  const fetchAgents = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await boApiClient.proxyWebAgentAgents.getAllAgents();
      setAgents(response);
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      setError(error instanceof Error ? error : new Error("Failed to fetch agents"));
    } finally {
      setLoading(false);
    }
  };

  const updateAgentPrompt = async (agentId: string, updatedPrompt: string) => {
    try {
      const agent = agents.find(a => a.id === agentId);
      if (!agent) throw new Error("Agent not found");

      const request: AgentConfigDTO = {
        url: agent.config.url,
        prompt: updatedPrompt,
        options: agent.config.options,
        // @ts-expect-error
        parameters: agent.config.parameters,
      };

      await boApiClient.proxyWebAgentAgents.updateAgent(agentId, request);
      return true;
    } catch (error) {
      console.error("Failed to update agent:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchAgents();
  }, [selectedTab]);

  return { agents, loading, error, refetch: fetchAgents, updateAgentPrompt };
};
