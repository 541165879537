/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatusMappingRequestDto } from '../models/StatusMappingRequestDto';
import type { StatusMappingResponseDto } from '../models/StatusMappingResponseDto';
import type { StatusMappingSectionDto } from '../models/StatusMappingSectionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class StatusMappingAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param section
   * @returns StatusMappingSectionDto Success
   * @throws ApiError
   */
  public getStatusMappingsForSection(
    section: string,
  ): CancelablePromise<StatusMappingSectionDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/status-mappings/section/{section}',
      path: {
        'section': section,
      },
    });
  }

  /**
   * @param requestBody
   * @returns StatusMappingResponseDto Success
   * @throws ApiError
   */
  public mapStatus(
    requestBody?: StatusMappingRequestDto,
  ): CancelablePromise<StatusMappingResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/status-mappings/map',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
