import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import React from "react";

export function TutorialMessageBox() {
  return (
    <Box sx={{ display: "flex", p: 2, flexDirection: "row" }}>
      <Box width="100%">
        <Box>
          <Typography color="#666">Guide the agent with clear instructions.</Typography>
          <Typography color="#666" marginBottom={2}>
            To retrieve data, write each action as a clear command for the bot to follow, from login
            to download. Keep steps simple and specific to ensure accuracy.
          </Typography>
          <Typography color="#666">Example prompt:</Typography>
          <Typography color="#666">
            1. Log in using $username and $password (using post-processing macros to keep login
            confidential)
          </Typography>
          <Typography color="#666">2. Go to the 'Transactions' page. </Typography>
          <Typography color="#666">3. Filter for transactions from the last month.</Typography>
          <Typography color="#666">4. Download the filtered transactions as a CSV file.</Typography>
          <Typography color="#666">5. Save the CSV file to [specific file path].</Typography>
          <Typography color="#666">
            The agent will confirm each step with you before moving on.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
