import { Box, Typography } from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";

const ThoughtsPlaceholder = () => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
        Browser agent
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        <RestoreIcon sx={{ fontSize: 14, color: "rgba(0, 0, 0, 0.6)" }} />
        <Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
          Estimated run 4 minutes
        </Typography>
      </Box>
    </Box>

    <Box
      sx={{
        p: 3,
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderRadius: "6px",
      }}
    >
      <Typography color="text.secondary">
        Agent thoughts will appear here once run is completed.
      </Typography>
    </Box>
  </Box>
);

export default ThoughtsPlaceholder;
