/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentConfigDto } from '../models/AgentConfigDto';
import type { AgentRunHistoryDto } from '../models/AgentRunHistoryDto';
import type { AgentTimerDto } from '../models/AgentTimerDto';
import type { FileUrlDto } from '../models/FileUrlDto';
import type { RunResponseDto } from '../models/RunResponseDto';
import type { WebAgentDto } from '../models/WebAgentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProxyWebAgentAgentsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns WebAgentDto Success
   * @throws ApiError
   */
  public getAllAgents(): CancelablePromise<Array<WebAgentDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/web-agent/agent',
    });
  }

  /**
   * @param name
   * @param requestBody
   * @returns WebAgentDto Success
   * @throws ApiError
   */
  public createAgent(
    name?: string,
    requestBody?: AgentConfigDto,
  ): CancelablePromise<WebAgentDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/web-agent/agent',
      query: {
        'name': name,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param agentId
   * @returns any Success
   * @throws ApiError
   */
  public deleteAgent(
    agentId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/web-agent/agent/{agentId}',
      path: {
        'agentId': agentId,
      },
    });
  }

  /**
   * @param agentId
   * @returns WebAgentDto Success
   * @throws ApiError
   */
  public getAgent(
    agentId: string,
  ): CancelablePromise<WebAgentDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/web-agent/agent/{agentId}',
      path: {
        'agentId': agentId,
      },
    });
  }

  /**
   * @param agentId
   * @param requestBody
   * @returns WebAgentDto Success
   * @throws ApiError
   */
  public updateAgent(
    agentId: string,
    requestBody?: AgentConfigDto,
  ): CancelablePromise<WebAgentDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/web-agent/agent/{agentId}',
      path: {
        'agentId': agentId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param agentId
   * @param requestBody
   * @returns AgentTimerDto Success
   * @throws ApiError
   */
  public addTimer(
    agentId: string,
    requestBody?: string,
  ): CancelablePromise<AgentTimerDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/web-agent/agent/{agentId}/timer',
      path: {
        'agentId': agentId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param timerId
   * @param agentId
   * @returns any Success
   * @throws ApiError
   */
  public deleteTimer(
    timerId: string,
    agentId: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/web-agent/agent/{agentId}/timer/{timerId}',
      path: {
        'timerId': timerId,
        'agentId': agentId,
      },
    });
  }

  /**
   * @param agentId
   * @returns RunResponseDto Success
   * @throws ApiError
   */
  public startRun(
    agentId: string,
  ): CancelablePromise<RunResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/web-agent/agent/{agentId}/run',
      path: {
        'agentId': agentId,
      },
    });
  }

  /**
   * @param runId
   * @param agentId
   * @returns FileUrlDto Success
   * @throws ApiError
   */
  public getFiles(
    runId: string,
    agentId: string,
  ): CancelablePromise<Array<FileUrlDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/web-agent/agent/{agentId}/run/{runId}/files',
      path: {
        'runId': runId,
        'agentId': agentId,
      },
    });
  }

  /**
   * @param runId
   * @param agentId
   * @param fileName
   * @returns any Success
   * @throws ApiError
   */
  public downloadFile(
    runId: string,
    agentId: string,
    fileName?: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/web-agent/agent/{agentId}/run/{runId}/download',
      path: {
        'runId': runId,
        'agentId': agentId,
      },
      query: {
        'fileName': fileName,
      },
    });
  }

  /**
   * @param agentId
   * @param runId
   * @returns AgentRunHistoryDto Success
   * @throws ApiError
   */
  public getRunHistory(
    agentId: string,
    runId: string,
  ): CancelablePromise<AgentRunHistoryDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/web-agent/agent/{agentId}/run/{runId}',
      path: {
        'agentId': agentId,
        'runId': runId,
      },
    });
  }

  /**
   * @param agentId
   * @returns RunResponseDto Success
   * @throws ApiError
   */
  public getLatestRun(
    agentId: string,
  ): CancelablePromise<RunResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/web-agent/agent/{agentId}/latest-run',
      path: {
        'agentId': agentId,
      },
    });
  }

}
